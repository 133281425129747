<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card class="primary--border student_list" outlined>
          <v-card-title class="title">
            List of
            <v-menu offset-y transition="slide-y-reverse-transition">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" text
                  >{{ selected_list_type }}
                  <v-icon ml-2>arrow_drop_down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-subheader>Select Type</v-subheader>
                <v-list-item
                  v-for="(item, index) in studentTypes"
                  :key="index"
                  @click="(selected_list_type = item), batch_type = 'CURRENT', get()"
                >
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            students
            <v-spacer />
            <v-btn
                :color="'success'"
                class="white--text"
                outlined
                small
                @click="downloadClassWiseStudentRecord"
              >
                <v-icon left small>cloud_download</v-icon>
                &nbsp;Download Student Class Wise 
              </v-btn>
            &nbsp;&nbsp;&nbsp;
            <v-download-column-select
              v-if="
               ( $auth.isAdmin() || $auth.loggedInRole().slug === 'administrator' || $auth.loggedInRole().slug === 'receptionist') 
                && (form.items.meta.total > 0)
              "
              :showPDF="false"
              :downloadColumns="dynamicColumns"
              :showQr="students.search.filters.grade ? true: false"
              @onDownload="downloadStudent"
            >
              DOWNLOAD STUDENT RECORD
            </v-download-column-select>
            <!-- <download-button
              v-if="
                $auth.isAdmin() || $auth.loggedInRole().slug === 'administrator'
              "
              @action="downloadStudent('all')"
              >Download Student Record
            </download-button> -->
          </v-card-title>

          <v-scroll-y-transition>
            <v-card outlined>
              <v-card-title class="pb-0 mb-0">
                <v-flex xs2>
                  <v-select
                    :disabled="students.search.grades.length < 1"
                    @change="getSections(), get()"
                    :items="students.search.grades"
                    class="pa-0"
                    label="Select grade"
                    outlined
                    dense
                    v-model="students.search.filters.grade"
                  />
                </v-flex>
                <v-flex xs2>
                  <v-select
                    :disabled="students.search.sections.length < 1"
                    :items="students.search.sections"
                    @change="get()"
                    class="pa-0"
                    label="Select section"
                    outlined
                    dense
                    v-model="students.search.filters.section"
                  />
                </v-flex>
                <v-flex xs2>
                  <v-text-field
                    hint="At least 3 characters"
                    color="primary"
                    class="pa-0"
                    label="Student Code or Name"
                    v-model="search"
                    outlined
                    dense
                    />
                    <!-- @input="searchStudents" -->
                </v-flex>
                 <v-flex xs2>
                  <v-select
                    :items="student_groups"
                    class="pa-0"
                    label="Select Group"
                    outlined
                    dense
                    v-model="student_group_id"
                    @change="get()"
                  />
                </v-flex>
                
                 <v-flex xs2>
                  <v-select
                    v-if="selected_list_type === 'LEFT'"
                    :items="batch_list_type"
                    class="pa-0"
                    label="Batch Type"
                    outlined
                    dense
                    v-model="batch_type"
                    @change="get()"
                  />
                </v-flex>
                
                <v-flex xs1>
                  <v-btn
                    v-if="students.search.filters.grade  || student_group_id"
                    outlined
                    @click="clearFilter"
                    class="mt-n7"
                  >
                    <v-icon dark small>clear</v-icon>
                  </v-btn>
                </v-flex>
                <v-flex xs1>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="moreFilterDialogue = true"
                    outlined
                    color="primary"
                    style="margin-top: -20px; float: right"
                    ><v-icon>filter_alt</v-icon></v-btn
                  >
                </v-flex>
              </v-card-title>
            </v-card>
          </v-scroll-y-transition>
          <v-data-table
            :footer-props="rowsPerPageItems"
            :headers="headers"
            :items="form.items.data"
            :options.sync="pagination"
            :server-items-length="form.items.meta.total"
            style="margin-top: 15px"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">
                  <v-image-preview
                    @onUpload="get()"
                    :user-id="item.id"
                    :title="item.name"
                    :src="item.profile_image || $auth.company('logo') "
                  ></v-image-preview>
                </td>
                <td class="text-xs-left">
                  <span v-if="pagination.sortBy === 'name'"
                    >{{ item.name }}
                  </span>
                  <span v-else>{{ item.name }}</span>
                  <div>
                    <span style="font-size: 10px; color: #666"
                      >(DOB: {{ item.personal.dob }})</span
                    >
                  </div>
                </td>
                <td class="text-xs-left">
                  {{ item.enroll_code }}
                  <div>
                    <span style="font-size: 10px; color: #666"
                      >({{ item.admission_date }})</span
                    >
                  </div>
                </td>
                <!--<td class="text-xs-left">{{ item.admission_date }}</td>-->
                <td class="text-left">
                  {{ item.grade }} <sup>{{ item.section }}</sup>
                </td>
                <td>
                  <v-edit-dialog
                    v-if="
                      students.search.filters.grade &&
                      students.search.filters.section
                    "
                    :return-value.sync="item.roll"
                    large
                    lazy
                    persistent
                    @save="save(item)"
                    @cancel="cancel"
                    @open="open(item)"
                    @close="close"
                  >
                    <div class="text-xs-center" style="padding-left: 18px">
                      {{ item.roll }}
                    </div>
                    <template v-slot:input>
                      <br />
                      <v-text-field
                        dense
                        v-model="item.roll"
                        label="Roll no"
                        single-line
                        autofocus
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                  <div class="text-xs-center" v-else>{{ item.roll }}</div>
                </td>
                <td class="text-xs-left">
                  <span v-if="item.guardian">
                    {{ item.guardian.name }}
                    <div v-if="item.guardian.mobile">
                      <span style="font-size: 10px; color: #666"
                        >({{ item.guardian.mobile }})</span
                      >
                    </div>
                  </span>
                </td>
                <td class="text-right">
                  <view-button
                    :onCurrentBatch="false"
                    v-if="selected_list_type === 'CURRENT'"
                    @action="
                      (password_update_form.user_id = item.id),
                        (updatePasswordModal = true)
                    "
                    permission="student-update"
                    icon="lock"
                    color="warning"
                    label="Password"
                  ></view-button>
                  <edit-button
                    :onCurrentBatch="false"
                    v-if="selected_list_type === 'LEFT'"
                    icon="autorenew"
                    @agree="
                      (recoverDialog = true), (recoverSelectedStudent = item)
                    "
                    :permission="'student-delete'"
                    label="Recover Student"
                  ></edit-button>
                  &nbsp;
                  <router-link
                    v-if="selected_list_type === 'CURRENT'"
                    :to="'/student/' + item.id"
                  >
                    <view-button permission="student-profile-view" />
                  </router-link>
                  &nbsp;
                 <v-btn xSmall outlined color="blue" @click="printQrCard(item)">
                  Print
                 </v-btn>
                </td>
              </tr>
            </template>
            <template v-slot:no-data>
              <v-alert
                v-if="!loading"
                :value="true"
                color="error"
                icon="warning"
              >
                No students record available for the given batch
                {{ batch.name }}
              </v-alert>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog
      v-model="moreFilterDialogue"
      scrollable
      persistent
      max-width="550"
    >
      <v-card outlined class="primary--border">
        <v-card-title class="primary white--text">
          <v-icon class="mr-2 white--text">filter_alt</v-icon>
          More Filters
        </v-card-title>

        <v-card-text>
          <br />
          <v-form
            ref="form"
            @keydown.native="form.errors.clear($event.target.name)"
            lazy-validation
          >
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex xs12 sm6>
                  <v-text-field
                    v-mask="'####-##-##'"
                    v-model="students.search.filters.admission_from"
                    outlined
                    label="Admission From"
                    dense
                    placeholder="YYYY-MM-DD"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    v-mask="'####-##-##'"
                    label="Admission To"
                    v-model="students.search.filters.admission_to"
                    outlined
                    dense
                    placeholder="YYYY-MM-DD"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-calendar-field
                    id-val="student_from_dob"
                    v-model="student_from_dob"
                    label="From Dob"
                  >
                  </v-calendar-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-calendar-field
                    id-val="student_to_dob"
                    v-model="student_to_dob"
                    label="To Dob"
                  >
                  </v-calendar-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-select
                  
                    :items="gender_type"
                    class="pa-0"
                    label="Select Gender"
                    outlined
                    dense
                    v-model="gender"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>

          <v-flex xs12 style="margin-top: 15px">
            <v-divider></v-divider>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            outlined
            small
            @click="moreFilterDialogue = false"
            >Close</v-btn
          >
          <v-btn
            color="warning"
            outlined
            small
            @click="
              (moreFilterDialogue = false),
                (students.search.filters.admission_from = ''),
                (students.search.filters.admission_to = ''),
                get()
            "
            >Clear and Close</v-btn
          >
          <v-btn
            color="success"
            outlined
            small
            @click="get(), (moreFilterDialogue = false)"
          >
            Filter Now
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="updatePasswordModal"
      scrollable
      persistent
      max-width="500px"
    >
      <v-card outlined class="primary--border">
        <v-card-title class="primary white--text">
          <span class="title">Update Password</span>
        </v-card-title>
        <v-card-text>
          <div class="mt-0">
            <v-form
              ref="form"
              @submit.prevent="store"
              @keydown.native="
                password_update_form.errors.clear($event.target.name)
              "
              @keyup.enter="store"
              lazy-validation
            >
              <v-container grid-list-md>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-icon>mdi-eye</v-icon>
                    <v-text-field
                      autocomplete="current-password"
                      label="Password*"
                      required
                      class="pa-0"
                      tabindex="1"
                      :type="show2 === true ? 'text' : 'password'"
                      hint="At least 8 characters"
                      counter
                      @click:append="show2 = !show2"
                      :append-icon="!show2 ? 'lock' : 'lock_open'"
                      v-model="password_update_form.password"
                      name="password_std"
                      outlined
                      dense
                      :error-messages="form.errors.get('password')"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      tabindex="2"
                      :type="show2 === true ? 'text' : 'password'"
                      autocomplete="current-password"
                      label="Confirm Password*"
                      required
                      class="pa-0"
                      v-model="password_update_form.password_confirmation"
                      name="password_std_confirm"
                      outlined
                      dense
                      :error-messages="form.errors.get('confirm_password')"
                    />
                  </v-flex>

                  <v-flex xs12>
                    <small>*indicates required field</small>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="updatePasswordModal = false"
            >Close</v-btn
          >
          <v-btn
            :disabled="
              password_update_form.password.length < 8 ||
              password_update_form.password !==
                password_update_form.password_confirmation
            "
            color="success"
            outlined
            text
            @click="updatePassword"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="recoverDialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title"
            >Recover {{ recoverSelectedStudent.name }} ({{
              recoverSelectedStudent.enroll_code
            }})</span
          >
        </v-card-title>
        <v-card-text>
          <br />
          <alert-message
            title="Recovery"
            :extraSmall="true"
            message="Please input student's enroll code in the below input field"
          ></alert-message>

          <br />
          <v-text-field
            flat
            color="primary"
            class="pa-0"
            label="Student's Enroll Code"
            v-model="recoveryEnroll"
            append-icon="autorenew"
            outlined
            dense
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="recoverDialog = false">
            Close
          </v-btn>
          <v-btn
            :disabled="
              recoveryEnroll === '' ||
              recoveryEnroll !== recoverSelectedStudent.enroll_code
            "
            color="success"
            text
            @click="recoverStudent"
            >Recover Now</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<style scoped lang="scss">
.student_list {
  .v-btn:not(.v-btn--round).v-size--default {
  }
  .v-btn__content {
    font-size: 18px !important;
  }
}

.student_list .v-btn:not(.v-btn--round).v-size--default[data-v-289fa12a] {
  font-size: 16px !important;
  padding: 0 0 0 5px;
  font-weight: bold;
}
.inputLabelabsent {
  background: #e22712;
  color: white;
}

.inputLabelLeave {
  background: #29b6f6;
  color: white;
}

.inputLabelLate {
  background: #ffb74d;
  color: black;
}

.inputRadio {
  display: none;
}
</style>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";
import CommonFunction from "@/library/CommonFunction";
import {redirectDownloadUrl, passDataToExternalDomain } from "@/library/helpers";
let typingTimer;
let doneTypingInterval = 400;
const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();
export default {
  mixins: [Mixins,CommonFunction],
  data: () => ({
    moreFilterDialogue: false,
    rowsPerPageItems: { "items-per-page-options": [10, 25, 50, 75, 100, 150] },
    downloadColumns: [
      { value: "enroll_code", name: "Enroll Code", selected: true },
      { value: "name", name: "Student's Name", selected: true },
      { value: "gender", name: "Gender", selected: false },
      {
        value: "father",
        name: "Father's Name",
        selected: false,
        toAdmin: true,
      },
      {
        value: "mother",
        name: "Mother's Name",
        selected: false,
        toAdmin: true,
      },
      { value: "dob", name: "Date of Birth", selected: false, toAdmin: false },
      { value: "age", name: "Age (Yr)", selected: false, toAdmin: false },
      { value: "ethnicity", name: "Caste", selected: false, toAdmin: true },
      { value: "grade", name: "Grade", selected: true },
      { value: "section", name: "Section", selected: true },
      { value: "roll", name: "Roll", selected: true },
      { value: "admission_date", name: "Admission Date", selected: false },
      {
        value: "guardian_name",
        name: "Guardian",
        selected: false,
        toAdmin: true,
      },
      { value: "mobile", name: "Contact No.", selected: false, toAdmin: true },
      { value: "gender", name: "Gender", selected: false, toAdmin: true },
      { value: "address", name: "Address", selected: true, toAdmin: true },
      { value: "batch_name", name: "Batch Name", selected: false, toAdmin: true },
      { value: "left_date", name: "Left Date", selected: false, toAdmin: true },
     
    ],
   
    student_groups: [],
    student_list_types: ["CURRENT", "LEFT"],
    selected_list_type: "CURRENT",
    recoverDialog: false,
    recoverSelectedStudent: {},
    recoveryEnroll: "",
    show2: false,
    updatePasswordModal: false,
    student_code: "",
    student_id: "",
    student_group_id:"",
    form: new Form({}, "/api/search-student"),
    password_update_form: new Form(
      {
        password_confirmation: "",
        password: "",
        user_id: "",
      },
      "/api/user/change-password"
    ),
    search: "",
    batches: [],
    // pagination: {},

    loading: true,
    headers: [
      {
        text: "#",
        align: "left",
        value: "serial_no",
        width: 5,
        sortable: false,
      },
      {
        text: "Photo",
        align: "left",
        value: "profile_image",
        width: 20,
        sortable: false,
      },
      { text: "Name", align: "left", value: "name", width: 200 },
      {
        text: "Enroll Code",
        align: "left",
        value: "enroll",
        width: 105,
        sortable: false,
      },
      {
        text: "Grade",
        align: "left",
        value: "grade",
        sortable: false,
        width: 140,
      },
      {
        text: "Roll",
        align: "center",
        value: "roll",
        width: 10,
        sortable: true,
      },
      {
        text: "Guardian",
        align: "left",
        value: "guardian",
        sortable: false,
        width: 200,
      },
      {
        text: "Action",
        align: "right",
        value: "action",
        sortable: false,
        width: 170,
      },
    ],
    section: "",
    grade: "",
    batchId: "",
    sections: [],
    grades: [],
    filter: false,
    old_roll: "",
    student_from_dob: "",
    student_to_dob: "",
    gender: 'all',
    gender_type: ['All', 'Male', 'Female'],
    showQr: false,
    batch_list_type: ["CURRENT", "ALL"],
    batch_type: "CURRENT"

  }),
  watch: {
    pagination: function () {
      this.get();
    },
    batch: function () {
      this.get();
      this.getGrades();
    },
    grade: function (value) {
      this.get();
    },
    search(val) {
      if (!val) return this.get();

      if (val.length < 1) return;

      // Items have already been requested
      if (this.loading) return;

      let $this = this;
      clearTimeout(typingTimer);
      typingTimer = setTimeout(function() {
        $this.get();
      }, doneTypingInterval);
    },
   
  },
  computed: {
    ...mapState(["batch", "students"]),
    studentTypes() {
      let $this = this;
      return this.student_list_types.filter(function (statement) {
        return $this.selected_list_type !== statement;
      });
    },
    dynamicColumns() {
      let columns = [];
      if(this.selected_list_type === 'CURRENT') {
        this.downloadColumns
        .filter(item => item.value !== 'left_date' &&  item.value !== 'batch_name') 
        .map((item) => {
          columns.push(item);
        });
      }else {
        this.downloadColumns.map((item) => {
          columns.push(item);
        });

      }
      return columns;
    },
  },
  mounted() {
    this.getGrades();
    if (this.$route.query.list_type === "LEFT") {
      this.selected_list_type = "LEFT";
    }
    this.getStudentGroup();
  },
  methods: {
    recoverStudent() {
      const enrollCode = this.recoverSelectedStudent.enroll_code;
      if (enrollCode) {
        this.$rest
          .post("/api/enroll/recover/student", { enroll_code: enrollCode })
          .then((res) => {
            this.recoverDialog = false;
            this.recoverSelectedStudent = {};
            this.recoveryEnroll = "";
            this.$events.fire("notification", {
              message: res.data.message,
              status: "success",
            });
            this.get();
          });
      }
    },
    updatePassword() {
      this.password_update_form.fireFetch = false;
      this.password_update_form.store().then(() => {
        this.updatePasswordModal = false;
      });
    },
    get(params) {
      if (
        ["teacher"].includes(this.$auth.getRole()) &&
        this.students.search.filters.grade
      ) {
        this.getMethod(params);
      } else if (!["teacher"].includes(this.$auth.getRole())) {
        this.getMethod(params);
      } else {
      }
    },

    getMethod(params) {
      let extraParams =
        "sectionId=" +
        this.students.search.filters.section.toString() +
        "&search=" +
        this.search.toString() +
        "&gradeId=" +
        this.students.search.filters.grade.toString() +
        "&slim=true" +
        "&list_type=" +
        this.selected_list_type +
        `&admission_from=${this.students.search.filters.admission_from}
        &admission_to=${this.students.search.filters.admission_to}`+"&student_group_id="+this.student_group_id+"&from_dob="+this.student_from_dob+"&to_dob="+this.student_to_dob+"&batch_type="+this.batch_type+"&gender="+this.gender;

      this.loading = true;
      let query = [null, undefined].includes(params)
        ? this.queryString(extraParams)
        : params;
      this.form.get(null, query).then(({ data }) => {
        let $this = this;
        this.form.items.data = data.data.map(function (item) {
          item.edit_roll = false;
          return item;
        });
        this.pagination.totalItems = data.meta.total;
        this.updateState = !!data.update;
        this.loading = false;
      });
    },
    getStudentGroup(){
      this.$rest.get("/api/student-group")
      .then(({data}) => {
        data.data.map((item) => {
            this.student_groups.push({text: item.name, value: item.id});
        });
      })
      this.student_groups.push({text: 'Ungrouped', value: 'Ungrouped'});

    },
    editRoll(index) {
      this.form.items.data[index].edit_roll = true;
    },
    getGrades() {
      this.$rest
        .get("/api/grades?rowsPerPage=20&sortBy=rank&descending=false")
        .then(({ data }) => {
          this.students.search.grades = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        });
    },
    getSections() {
      this.$rest
        .get(
          "/api/section?batch=" +
            this.batchId +
            "&grade=" +
            this.students.search.filters.grade
        )
        .then(({ data }) => {
          this.students.search.sections = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        });
    },
    searchStudents: function (v) {
      if (v.length > 2 || !v.length) this.get();
    },
    getBatches() {
      this.$rest.get("/api/batch").then(({ data }) => {
        this.batches = data.data.map((item) => {
          return { value: item.id, text: item.name };
        });
      });
    },
    clearFilter() {
      this.students.search.filters.grade = "";
      this.students.search.filters.section = "";
      this.students.search.sections = [];
      this.search = "";
      this.form.items.data = [];
      this.student_group_id = '';
      this.get();

    },
    viewProfile(stdId) {
      this.$router.push({
        name: "student",
        params: {
          id: stdId,
        },
      });
    },
    getStudentId(id) {
      this.student_id = id;
    },
    save(value) {
      this.$rest
        .put("/api/roll/" + value.enroll_id, {
          roll: value.roll,
          section_id: value.section_id,
        })
        .then(({ data }) => {
          this.$events.fire("notification", { message: data.message });
        })
        .catch((err) => {
          value.roll = this.old_roll;
          this.$events.fire("notification", {
            message: err.response.data.message,
            status: "error",
          });
        });
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceled";
    },
    open(item) {
      this.old_roll = item.roll;
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Dialog opened";
    },
    close() {},

    downloadStudent({ type, columns, columnString }) {
      if(type === 'qr_code') return this.printQrCard('', 'bulk');
      let queryString =
        "gradeId=" +
        this.students.search.filters.grade +
        "&sectionId=" +
        this.students.search.filters.section +
        `&flag=all&type=${type}&column=${columnString}&admission_from=${this.students.search.filters.admission_from}&admission_to=${this.students.search.filters.admission_to}` +
        "&list_type=" +
        this.selected_list_type+"&from_dob="+this.student_from_dob+"&to_dob="+this.student_to_dob+"&student_group_id="+this.student_group_id + "&batch_type="+this.batch_type+"&gender="+this.gender;
      redirectDownloadUrl({uri:'/download/student/list', queryString });
    },

    printQrCard(item, type='single'){
      let queryString =''
      if(type === 'single') {
        let contact = item.primary_mobile === null ? item.guardian.mobile : item.primary_mobile;
        queryString = "name="+item.full_name+"&e_code="+item.enroll_code+"&e_id="+item.enroll_id+
                          "&grade="+item.grade+"&grade_id="+item.grade_id+"&section="+item.section+
                          "&sec_id="+item.section_id+"&contact="+contact+"&card_type="+type;

      }else{
        queryString = "grade_id="+this.students.search.filters.grade+"&section_id="+this.students.search.filters.section+"&card_type="+type;
      }

      redirectDownloadUrl({uri:'/download/student/card-with-qr', queryString });

    },
  },
};
</script>
<style lang="scss" scoped>
.v-chip,
.v-chip .v-chip__content {
  border-radius: 3px;
  color: white;
  font-weight: bold;
}
</style>
