export default {
    data: () => ({
        studentGroups:[]
    }),
    methods: {
        getStudentGroup(){
            // console.log("from common functio");
            this.$rest.get('/api/student-group')
            .then(({data})=>{
                this.studentGroups = data.data.map(item => {
                    return {value: item.id, text: item.name}
                });
            })
        }
    }
}